.adress{
    margin: 20px;
    padding: 15px;
}

.adress li{
    cursor: pointer;
   
}
.adress li:hover{
    color: red;
}

.footer {
    padding: 40px 10%;
    background-color: black;
}
.footer-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.footer-column {
    flex: 1;
}
.footer-column h3 {
    font-size: 18px;
    margin-bottom: 15px;
}
.footer-column ul {
    list-style-type: none;
    padding: 0;
}
.footer-column ul li {
    margin-bottom: 8px;
}
.footer-column ul li a {
    color: #a0a0a0;
    text-decoration: none;
    font-size: 14px;
}
.newsletter input[type="email"] {
    width: 70%;
    padding: 10px;
    border: none;
    border-radius: 4px 0 0 4px;
}
.newsletter button {
    padding: 10px 15px;
    background-color: #ff6b00;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}
.social-icons {
    margin-top: 15px;
}
.social-icons a {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    border-radius: 50%;
    margin-right: 10px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    color: #0a0a23;
    text-decoration: none;
}
.footer-bottom { 
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #333;
    padding-top: 20px;
}
.footer-logo img {
    height: 40px;
}
.footer-links a {
    color: #a0a0a0;
    text-decoration: none;
    margin-left: 20px;
    font-size: 14px;
}